<template>
  <div class="header">
    <div span="12"
         class="spread"
         @click="menuShow">
      <img src="@/assets/img/spread.png"
           class="spread_img" />

      <img src="@/assets/img/logo.png" @click.stop="bNav('HomeIndex')" class="logo_img" alt="">
    </div>
    <div span="12"
         class="rightItem">
      <!-- <img src="@/assets/img/set.png"
           @click="handleSettingShow"
           alt=""
      class="rightIcon" />-->
      <div class="addressBox"
           v-if="account">
        <div class="account">{{ getAccount }}</div>
      </div>
      <van-button type="primary"
                  class="loginBtn"
                  v-else
                  @click="handleLogin">{{ $t('lang.swap55') }}</van-button>
    
      <div class="settings">
        <div class="language_switching"
             @click="switchNetWorkConfig">
          <img class="language" style="margin-right: 5px;"
               :src="currentNetWorkDel.logoURI" />
          <span>{{ currentNetWorkDel.key }}</span>
        </div>
        <div v-show="NetWorkSelect">
          <div class="multilingual" style="width: 150px;">
            <div style="border-bottom: 1px solid #356ef1; color: #356ef1; text-align: left; padding-left: 10px;">
              {{ $t('lang.swap402') }}
            </div>
            <div v-for="(item, index) of actionsNetWorkConfig"
                 :key="index"
                 @click="NetWorkConfigSwitching(item)"
                 :class="item.key == currentNetWorkDel.key ? 'special' : ''" style="display: flex;align-items: center;padding-left: 5px; ">
                 <img class="language" style="margin-right: 10px;"
               :src="item.logoURI" />
              <span>{{ item.NetWorkName }}</span>
            </div>
          </div>
          <div class="overlay"
               @click="handleAction"></div>
        </div>
      </div>
      <div class="settings">
        <div class="language_switching"
        style="border-radius: 50%;"
             @click="switchLanguage">
          <img class="language"
               src="@/assets/img/lang.png" />
          <!-- <span>{{ currentLanguage }}</span> -->
        </div>
        <div v-show="multilingualCondition">
          <div class="multilingual" style="right: -30px;">
            <div v-for="(item, index) of actions"
                 :key="index"
                 @click="languageSwitching(item)"
                 :class="item.text == currentLanguage ? 'special' : ''">
              <span>{{ item.text }}</span>
            </div>
          </div>
          <div class="overlay"
               @click="handleAction"></div>
        </div>
      </div>
    </div>

    <van-popup v-model="show"
               round
               position="left"
               @close="close"
               class="side">
      <div class="menu">
        <div class="user">
          <div class="invitation">
            <div>
              <img src="@/assets/img/invitations.png"
                   class="invitation_img" />
              <span>{{ $t('lang.swap80') }}</span>
            </div>
            <div class="more"
                 @click="toNav('InviteIndex')">
              <span>{{ $t('lang.swap131') }}</span>
            </div>
          </div>
          <span class="invite_detail">{{ $t('lang.header1') }}:</span>
          <div class="address">
            <div class="invitation_address">
              <span>{{userInfo.parent_id>0? userInfo.invite_code :'--' }}</span>
            </div>
            <div class="copys"
                 @click="copy"
                 :data-clipboard-text="userInfo.invite_code">
              <span>{{ $t('lang.swap61') }}</span>
            </div>
          </div>
        </div>
        <van-collapse v-model="activeNames">
          <van-collapse-item :name="index + 1"
                             v-for="(item, index) of menuList"
                             :key="index"
                             title-class="menu_li"
                             :is-link="item.subordinate.length != 0">
            <template #title>
              <div @click="toNav(item.router, 'title',item.subordinate.length)">
                <img :src="item.image"
                     class="slot_image" />
                <span>{{ $t(item.name) }}</span>
              </div>
            </template>
            <div v-for="(e, i) of item.subordinate"
                 :key="i"
                 class="menu_list"
                 @click="bNav(e.router,e.i)">
              <span>{{ $t(e.name) }}</span>
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
    </van-popup>
    <van-popup v-model="settingShow"
               round>
      <SettingsModal @listenClose="handleSettingClose" />
    </van-popup>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import SettingsModal from '@/components/SettingsModal.vue';
import axios from 'axios';
import Clipboard from 'clipboard';
import { invite, coin } from '@/api/index';
import { NetConfig } from '@/config/constants/netWorkConfig.js'
export default {
  name: 'Header',
  components: {
    SettingsModal,
  },
  data () {
    return {
      settingShow: false,
      showPopover: false,
      price: 0,
      currentLanguage: '',
      
      currentNetWorkDel:{
        text:'',
        logo:'',
      },
      NetWorkSelect:false,
      actions: [
        { text: '中文简体', lang: 'zh-CN', className: 'langPopover' },
        { text: 'English', lang: 'en-US', className: 'langPopover' },
        { text: '中文繁体', lang: 'zh-HK', className: 'langPopover' },
        { text: '한국인', lang: 'ko-KR', className: 'langPopover' },
        { text: '日本語', lang: 'ja-JP', className: 'langPopover' },
      ],
      actionsNetWorkConfig:[],
      show: false,
      menuList: [
        //下拉菜单
        {
          image: require('@/assets/img/logo.png'),
          name: 'lang.header3',
          subordinate: [],
          router: 'HomeIndex',
        },
        {
          image: require('@/assets/homelogo/icon1.png'),
          name: 'lang.header2',
          router: '',
          subordinate: [
            { name: 'lang.header4', router: 'Mine',i:0},
            { name: 'lang.header5', router: 'Mine',i:1 },
            { name: 'lang.header6', router: 'Mine',i:2 },
          ],
        },
       
        {
          image: require('@/assets/homelogo/icon2.png'),
          name: 'lang.header7',
          subordinate: [],
          router: 'gamesMall',
        },
        {
          image: require('@/assets/homelogo/icon3.png'),
          name: 'lang.header8',
          subordinate: [],
          router: '',
        },
        {
          image: require('@/assets/homelogo/icon4.png'),
          name: 'lang.header9',
          subordinate: [],
          router: 'Good',
        },
      ],
      activeNames: ['0'],
      multilingualCondition: false,
      userInfo: {},
    };
  },
  computed: {
    ...mapState(['account','netWorkConfig']),
    getAccount () {
      if (this.account) {
        return '0x...' + this.account.substring(this.account.length - 4, this.account.length);
      } else {
        return '';
      }
    },
  },

  watch: {
    account () {
      this.init();
      // this.finished = false;
    },
  },
  created () {
    this.actionsNetWorkConfig = NetConfig
    this.currentNetWorkDel =this.netWorkConfig
    if(this.netWorkConfig.key == 'BNB'){
      this.currentNetWorkDel = this.actionsNetWorkConfig[0]
      this.$store.commit('SETNETWORKCINFIG', this.currentNetWorkDel);
    }
  
    this.$i18n.locale = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN';
    // this.currentLanguage = this.$i18n.locale == 'zh' ? '中文' : 'English';
    for (let i in this.actions) {
      if (this.actions[i].lang == this.$i18n.locale) {
        this.currentLanguage = this.actions[i].text;
      }
    }
   
    this.init();
    // axios
    //   .get('https://api.pancakeswap.info/api/v2/tokens/0x25BF35B089DAcB352eadB802a685207d31E23a9F')
    //   .then(res => {
    //     this.price = (res.data.data.price * 1).toFixed(2);
    //   })
    //   .catch(e => { });
  },
  mounted () { },
  methods: {
    init () {
      if (this.account) {
        this.invitationAccount();
      }
    },
    invitationAccount () {
      let data = {
        address: this.account,
      };
      coin.getAccount(data).then(res => {
        if (res.code == 200) {
          this.userInfo = res.data.user_info;
        }
      });
    },
    handleLogin () {
      this.$store.dispatch('setWebProvider');
    },
    handleSettingShow () {
      this.settingShow = true;
    },
    handleSettingClose () {
      this.settingShow = false;
    },
    languageSwitching (item) {
      // let lang = this.lang.$i18n.locale === 'zh' ? 'en' : 'zh';

      this.currentLanguage = item.text;
      this.$i18n.locale = item.lang;
      localStorage.setItem('lang', item.lang);
      this.multilingualCondition = false;
    },
    NetWorkConfigSwitching (item){
           this.currentNetWorkDel =item
           this.$store.commit('SETNETWORKCINFIG', item);
           this.NetWorkSelect =false
    },
    changeLaguages () {
      let lang = this.$i18n.locale === 'zh' ? 'en' : 'zh';
      this.$i18n.locale = lang;
    },
    menuShow () {
      this.show = true;
    },
    close () {
      this.show = false;
      // console.log('close');
    },
    toNav (router, title, length) {
      if (title) {
        if (router != '') {
          if (router == 'chat') {
            window.location.href = 'https://h5.goodswap.club/#/pages/in/login';
          }else if(router =='hash'){
            window.location.href = 'https://pro.goodswap.club';
          } else {
            this.$router.push({ name: router });
          }
          this.close();
        } else {
          if (length == 0) {
            this.$toast(this.$t('lang.swap65'));
          }
        }
      } else {
        if (router != '') {
          if (router == 'chat') {
            window.location.href = 'https://h5.goodswap.club/#/pages/in/login';
          }else if(router =='hash'){
            window.location.href = 'https://pro.goodswap.club';
          } else {
            this.$router.push({ name: router });
          }
          this.close();
        } else {
          this.$toast(this.$t('lang.swap65'));
        }
      }
    },
    bNav (router, i) {
      console.log('router',router);
      if (router != '') {
          this.$router.push({ name: router, query: { id: i } });
        }
        this.close();
    
    },
    //语言弹窗的状态
    switchLanguage () {
      this.multilingualCondition = !this.multilingualCondition;
    },
    switchNetWorkConfig(){
               this.NetWorkSelect =!this.NetWorkSelect
    },
    handleAction () {
      this.multilingualCondition = false;
      this.NetWorkSelect =false
    },
    copy () {
      if (this.userInfo.parent_id>0) {
        var clipboard = new Clipboard('.copys');
        clipboard.on('success', e => {
          this.$toast(this.$t('lang.swap300'));
          clipboard.destroy(); // 释放内存
        });
        clipboard.on('error', e => {
          this.$toast(this.$t('lang.swap301'));
          clipboard.destroy(); // 释放内存
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  height: 60px;
  // box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-between;
 
  background: #fff;
  border-left: 0;
  border-right: 0;
  padding: 0 16px;
  .rightItem {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .language_switching {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      color: #595b5b;
      background: #3399ff;
      border-radius: 16px;
      padding: 4px 10px;
      margin-left: 20px;
    }
    .rightIcon {
      height: 20px;
      width: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
    .loginBtn {
      height: 30px;
      font-weight: bold;
      background: #ffffff00;
      border-radius: 16px;
      border: 1px solid #356ef1;
      color: rgba(0, 0, 0, 1) !important;
    }
  }
  .addressBox {
    // max-width: 150px;
    // line-height: 20px;
   
    font-size: 20px;
    // padding: 5px;
    display: flex;
    align-items: center;
  }
  .walletBox {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: -12px;
    z-index: 10;
    .wallet {
      height: 30px;
      width: 30px;
    }
  }
  .account {
    font-family: Kanit, sans-serif;
    font-size: 14px;
    font-weight: bold;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // background: #3399ff;
    padding: 2px 10px 2px 10px;
    line-height: 28px;
    color: rgba(0, 0, 0, 1);
    font-weight: bold;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 1);
    // box-shadow: 0px 1px 0px 0.5px #3399ff;
  }
  .spread {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    .spread_img {
      // display: block;
      width: 24px;
      height: 24px;
      margin: 0 10px;
    }
    .logo_img{
      width: 27px;
height: 24px;
    }
  }
  /deep/ .van-collapse-item,
  .van-hairline--top-bottom {
    border: 0 !important;
    position: static;
  }
  /deep/ .van-collapse-item__content {
    padding: 0;
    position: static;
  }
  /deep/ .van-collapse-item {
    padding: 16px 14px !important;
  }
  // /deep/ .van-collapse-item--border::after {
  //   border: 0 !important;
  // }
  /deep/ .van-hairline--top-bottom::after,
  .van-hairline-unset--top-bottom::after {
    border: 0 !important;
  }
  /deep/ .van-popup {
    border: 2px solid #3399ff;
  }
  .side {
    height: 100%;
    width: 70%;
    border: 2rpx solid #ffd0c1;
    .user {
      font-size: 18px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      background: linear-gradient(90deg, #356ef1ff 0%, #609ef0ff 49%, #386df1ff 100%);
      // box-shadow: 0px 3px 6px 1px #ffd85e;
      // border: 1px solid #ffd361;
      border-radius: 6px;
      padding: 10px 14px;
      margin: 0 14px 30px;
      .invitation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;

        margin-bottom: 20px;
        div {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .invitation_img {
          display: block;
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }
        .more {
          font-size: 12px;
          border-radius: 16px;
          // border: 1px solid #ffffff;
          padding: 2px 12px;
          color: rgba(255, 255, 255, 0.8);
        }
      }
      .invite_detail {
        width: 52px;
        height: 15px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.8);
      }
      .address {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: #333333;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        margin-top: 5px;
        .invitation_address {
          width: 70%;
          text-align: center;
          background: #ffffff66;
          color: #ffffff;
          border-radius: 6px;
          padding: 8px 16px;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }
        .copys {
          color: #fff;
          border-radius: 6px;
          padding: 6px 10px;
          margin-left: 10px;
          border: 1px solid #ffffffff;
          flex: 1;
          text-align: center;

          // width: 58px;
          // height: 27px;
          .span {
            // width: 24px;
            height: 17px;
            font-size: 12px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.8);
          }
        }
      }
    }
  }
  // /deep/ .van-popup {
  //   padding: 0 0 60px;
  // }
  .menu {
    // position: relative;
    height: 100%;
    // overflow: auto;
    background-color: #fff;
    border-radius: 0px 16px 16px 0px;
    // overflow: hidden;
    // border: 2px solid #3399ff;
    padding: 14px 0 60px;

    .menu_li {
      font-weight: bold !important;
      font-family: 'HarmonyOSSansSC';
      div {
        display: flex;
        align-items: center;
        font-size: 20px;
        color: #000000;
        font-weight: bold !important;
        font-family: 'HarmonyOSSansSC';
      }
    }
    .slot_image {
      width: 26px;
      height: 26px;

      margin-right: 12px;
    }
    .menu_list {
      font-weight: bold !important;
      font-family: 'HarmonyOSSansSC';
      color: #222222;
      font-size: 14px;
      padding: 8px 0 8px 30px;
    }
  }
  .settings {
    display: flex;
    position: relative;
    font-weight: bold;

    font-family: YouSheBiaoTiYuan-Regular, YouSheBiaoTiYuan;
    .language_switching {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;

      color: rgba(0, 0, 0, 1);
      background: #fff;
      border: 1px solid rgba(0, 0, 0, 1);
      border-radius: 16px;
      padding:  4px;
    }
    .language {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      // padding: 4px;
    }
    .multilingual {
      position: absolute;
      top: 34px;
      right: 0px;
      font-size: 14px;
      width: 92px;
      color: #333;
      background: #fff;
      box-shadow: 0px 2px 6px 0px rgba(16, 27, 82, 0.5);
      border-radius: 6px;
      text-align: center;
      padding: 2px;
      z-index: 999;
      div {
        padding: 6px 0;
      }
      .special {
        color: #3399ff;
      }
    }
    .overlay {
      position: fixed;
      z-index: 998;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
      margin-top: 48px;
    }
  }
}
@media screen and (min-width: 1000px) {
  .header {
    .side {
      height: 100%;
      width: 20%;
      border: 2rpx solid #ffd0c1;
    }
  }
}
@media screen and (min-width: 700px) {
  // .header {
  //   .spread {
  //     display: none;
  //   }
  // }
}
</style>
