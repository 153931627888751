export const lang = {
    swap1: 'スワップ',
    swap2: 'すぐにスワップを開始',
    swap3: 'から',
    swap4: '残高: ',
    swap5: '通貨を選択',
    swap6: '最大',
    swap7: '価格',
    swap8: 'スリッページ許容度',
    swap9: 'ウォレットを接続',
    swap10: '入力数量',
    swap11: '残高不足',
    swap12: 'この取引の流動性が不足しています',
    swap13: 'トークンを選択',
    swap14: '承認',
    swap15: 'パッケージ化',
    swap16: 'アンパッケージ化',
    swap17: 'スワップ',
    swap18: '取引レシート',
    swap19: '確認を待っています',
    swap20: '転送中',
    swap21: 'スワップを実行中',
    swap22: 'あなたのウォレットでこの取引を確認してください',
    swap23: '取引が送信されました',
    swap24: 'ブロックチェーンエクスプローラーで確認',
    swap25: '閉じる',
    swap26: '取引が拒否されました',
    swap27: 'スワップを確認',
    swap28: '出力は予想されるもので、少なくとも{inputOutMin} {symbol}を受け取ります。それ以外の場合は取引がキャンセルされます',
    swap29: '入力は予想されるもので、最大{inputInMax} {symbol}を販売します。それ以外の場合は取引がキャンセルされます',
    swap30: '最小受取量',
    swap31: '最大販売量',
    swap32: 'へ',
    swap33: 'トークンを管理',
    swap34: 'トークンをインポート',
    swap35: 'インポート',
    swap36: 'トークンを管理',
    swap37: 'カスタムトークン',
    swap38: 'すべてクリア',
    swap39: '名前で検索またはアドレスを貼り付け',
    swap40: '設定',
    swap41: 'デフォルト取引速度（GWEI）',
    swap42: '標準',
    swap43: '高速',
    swap44: '即時',
    swap46: '有効なスリッページのパーセンテージを入力してください',
    swap47: '取引が失敗する可能性があります',
    swap48: '取引が先行している可能性があります',
    swap49: '取引期限（分）',
    swap50: '推定',
    swap51: 'マルチホップ無効',
    swap52: '最近の取引',
    swap53: '最近の取引はありません',
    swap54: 'すべてクリア',
    swap55: '接続されていません',
    swap56: 'ボードに参加',
    swap57: '寄付',
    swap58: '権利付与',
    swap59: 'ボードメンバーリスト',
    swap60: '招待',
    swap61: 'コピー',
    swap62: '私のDAPP招待者:',
    swap63: '招待記録',
    swap64: 'エラーが発生しました。後で再試行してください',
    swap65: '機能はまだ開放されていません',
    swap66: '受け取る',
    swap67: '引き出し成功',
    swap68: '寄付中、確認待ち',
    swap69: '寄付済み',
    swap70: 'ホワイトリストに登録されていないユーザー',
    swap71: '寄付無効',
    swap72: '現在の残高不足',
    swap73: '承認中、少々お待ちください',
    swap74: '承認失敗',
    swap75: '寄付中、お待ちください',
    swap76: '寄付失敗',
    swap77: '交換',
    swap78: '流動性',
    swap79: 'メンバー',
    swap80: '招待',
    swap81: '権利プール',
    swap82: 'クロスチェーンブリッジ',
    swap83: 'プール',
    swap84: 'ファーム',
    swap85: 'ホーム',
    swap86: 'マーケット',
    swap87: '交換',
    swap88: '取引',
    swap89: '獲得',
    swap90: '確認',
    swap91: '価格影響',
    swap92: '最小受取量',
    swap93: '流動性供給者手数料',
    swap94: '流動性を追加',
    swap95: 'LPトークンを受け取るために流動性を追加',
    swap96: '流動性プールでのシェア',
    swap97: '交換レートと流動性プールシェア',
    swap98: 'あなたの流動性',
    swap99: 'トークンを取り戻すために流動性を削除',
    swap100: '流動性が見つかりません',
    swap101: 'あなたが追加した流動性プールが見当たりませんか？',
    swap102: '他のLPトークンを見る',
    swap103: 'プールに参加済み',
    swap104: '削除',
    swap105: '供給',
    swap106: 'プールをインポート',
    swap107: '既存の流動性プールをインポート',
    swap108: '流動性を探すためにトークンを選択してください。',
    swap109: 'この流動性プールには流動性を提供していません。',
    swap110: 'あなたのウォレット内のLPトークン',
    swap111: '受け取る',
    swap112: 'および',
    swap113: '数量',
    swap114: 'あなたは受け取る',
    swap115: '出力は予想されるもので、価格が{slippage}%を超えて変更された場合、取引がキャンセルされます',
    swap116: '焼却済み',
    swap117: '入金済み',
    swap118: '供給確認',
    swap119: '為替レート',
    swap120: 'あなたは最初の流動性提供者です。',
    swap121: 'あなたが追加したトークンの比率が、この流動性プールの初期価格を決定します。',
    swap122: '為替レートに満足したら、「供給」をクリックして確認してください。',
    swap123: '削除中',
    swap124: '供給中',
    swap125: '供給確認',
    swap126: 'ホワイトペーパー',
    swap127: '監査',
    swap128: 'アナウンス',
    swap129: 'ヒント',
    swap130: 'PHO 1:1 交換',
    swap131: 'もっと見る',
    swap132: '読み込み中',
    swap133: '情報',
    swap134: '寄付枠は満了しました',
    swap135: '私のPHO',
    swap136: 'ウォレット内のPHO',
    swap137: '収穫可能なPHO',
    swap138: 'PHO価格',
    swap139: 'PHO総量',
    swap140: 'PHO時価総額',
    swap141: 'PHO情報',
    swap142: '獲得済み',
    swap143: 'ステーキング',
    swap144: '個人TVL',
    swap145: 'コミュニティTVL',
    swap146: '収穫',
    swap147: '累積インセンティブ',
    swap148: '今日のインセンティブ',
    swap149: '参加者数',
    swap150: '私の招待リンク',
    swap151: '最大ハッシュレート区',
    swap152: '他のハッシュレート区',
    swap153: '私の友人の招待',
    swap154: 'キャンセル',
    swap155: '招待者を変更',
    swap156: '招待者は空欄にできません',
    swap157: '変更成功',
    swap158: 'LPトークンをステーク',
    swap159: 'LPトークンを解除',
    swap160: '解除',
    swap161: '取得',
    swap162: '現在、収益はありません',
    swap163: 'ルーティング',
    swap164: '監査機関',
    swap165: 'パートナー',
    swap166: '発行',
    swap167: 'アクティビティ',
    swap168: 'シングルトークンプール',
    swap169: 'LPプール',
    swap170: '国会',
    swap171: 'カード',
    swap172: 'ブラインドボックス',
    swap173: 'ゲーム',
swap174: '配分済みの権益プール総額',
swap175: '受け取るべき額',
swap176: '私は受け取った額',
swap177: '引き出し',
swap178: 'スーパー・ノード',
swap179: '受け取った権益カードのシェア',
swap180: '国庫',
swap181: '国庫の累積額',
swap182: '国庫の配分済み額',
swap183: '国庫の買い戻し額',
swap184: 'エキスパートモード',
swap185: 'GoodSwapはOKExChainベースで開発された、全ネット初の株式税制DeFiプラットフォームで、Swap、NFT、Gamefi、Lend、マシンガンプールなどの分野を順次導入しています。リリース以来、先進技術と公平で透明な市場理念を通じて、広範な資産価値向上プランと前例のない体験を提供し、多くのユーザーに愛されています。',
swap186: 'ワンクリックで引き出し',
swap187: '価格の影響が大きすぎます',
swap188: 'FAQ',
swap189: 'NFT',
swap190: 'コインを稼ぐ',
swap191: '進行中',
swap192: '終了',
swap193: '説明',
swap194: '株式権益エコカード、全世界で1680枚限定',
swap195: '株式権益エコカードを点灯させる条件と収益は以下の通りです',
swap196: '1つ星カード：個人アドレスに1000USDTの流動性があり、チームに1000USDTの流動性があり、収益は取引税0.1%に分配',
swap197: '2つ星カード：個人アドレスに2000USDTの流動性があり、チームに1万USDTの流動性があり、収益は取引税0.1%に分配',
swap198: '3つ星カード：個人に3000USDTの流動性があり、チームに10万USDTの流動性があり、スタジオ設立、収益は取引税0.1%に分配',
swap199: '4つ星カード：個人に4000USDTの流動性があり、チームに100万USDTの流動性があり、スタジオ設立、収益は取引税0.1%に分配',
swap200: '5つ星カード：個人に5000USDTの流動性があり、チームに1000万USDTの流動性があり、スタジオ設立、収益は取引税0.1%に分配',
swap201: '備考：すべての権益エコカードの収益はチェーン上で自動的に分配され、権益カードは取引所のすべてのエコボーナスを享受できます。権益カードは伝承、取引、譲渡が可能で、条件を満たすと自動的にアップグレードされ、条件を満たさない場合は自動的にダウングレードおよび自動的に退会します。',
swap202: '権益カード',
swap203: '開発ロードマップ：',
swap204: '2022年7月  2.0版の公開',
swap205: '2022年9月  保有トークンでの多挿入機能の開通',
swap206: '2022年12月 NFT取引プラットフォームの公開',
swap207: '2023年6月  Goodbabyゲームの公開',
swap208: '2023年9月  Goodlendの公開',
swap209: 'GoodSwapの紹介：',
swap210: 'スリップ：',
swap211: '分配：',
swap212: '購入',
swap213: '売却',
swap214: '0.5% LP分配',
swap215: '0.5% エコ権益カード分配',
swap216: '0.25% リバースプールLP破棄',
swap217: '0.25% エコシステム構築',
swap218: '現在の報酬はありません',
swap219: '個人TVL',
swap220: 'チームTVL',
swap221: 'LP数量',
swap222: 'ウォレット内のpho',
swap223: 'GDT',
swap224: 'GDTエアドロップ累計報酬',
swap225: '星級ユーザー累計報酬',
swap226: '手数料無料カウントダウン',
swap227: '引き出せる報酬',
swap228: 'LPマイニング報酬',
swap229: 'phoをステーキング',
swap230: 'phoをリデンプション',
swap231: '目標共識',
swap232: '達成された共識',
swap233: '共識',
swap234: 'アンロック済み',
swap235: '受け取れる',
swap236: 'コミュニティガバナンス',
swap237: 'アライアンス共識',
swap238: 'コミュニティアライアンス',
swap239: 'ステーキングトークン',
swap240: 'ステーキング中、少々お待ちください',
swap241: 'トレードマイニング',
swap242: '昨日の新規',
swap243: 'コミュニティホワイトリスト累計報酬',
swap244: '株式星カード累計報酬',
swap245: 'LPマイニング累計報酬',
swap246: '現在受け取れる',
swap247: '現在受け取れる金額',
swap248: 'トレードマイニング',
swap249: '私のシェア報酬累計',
swap250: '私のトレード報酬累計',
swap251: '取引ハッシュを入力してください',
swap252: '検証',
swap253: 'ハッシュを入力してください',
swap254: '取引アドレス',
swap255: 'あなたは次のように受け取ります',
swap256: 'GDTの購入および流動性の作成に関する取引のみが報酬を得られます',
swap257: 'GDT-PHOトークンをステーキング',
swap258: 'GDT-PHOトークンをリデンプション',
swap259: '全ネット手数料累計',
swap260: '受け取るべきボーナス',
swap261: 'GDT共同株主',
swap262: 'ソーシャル',
swap263: 'ショッピングモール',
swap264: '厳選された商品',
swap265: '選りすぐりのショップ',
swap266: '近日公開',
swap267: 'VGT共同創業者株主',
swap268: 'VGT-PHOトークンをステーキング',
swap269: 'VGT-PHOトークンをリデンプション',
swap270: '紹介リンク',
swap271: 'まずは招待者を追加してください',
swap272: 'まず招待者を追加してください',
swap273: '詳細',
swap274: 'メタバース',
swap275: 'グローバル',
swap276: '交換と流動性',
swap277: 'OKExChainをベースに開発されたネットワークで初めての株式税に基づくDefiプラットフォーム',
swap278: '徐々に機関銃プールなどのセクションをローンチします。革新的な技術と公正な市場理念を提供し、より広範な資産増加ソリューションと前例のない体験を提供し、ユーザーに愛されています。',
swap279: '発展のロードマップ',
swap280: '2022年7月 2.0版公開',
swap281: '2022年9月 コイン保有でマルチマイニング',
swap282: '2022年12月 NFT取引プラットフォーム公開',
swap283: '2023年6月 Goodbabyゲーム公開',
swap284: '2023年9月 Goodlend公開',
swap285: 'もっと知る',
swap286: 'あなたはすでに保有しています',
swap287: 'で',
swap288: '暗号通貨を取引',
swap289: '流動性とトークンをステーキング',
swap290: '配当を獲得',
swap291: '発射台',
swap292: '認証',
swap293: '優れた暗号通貨資産',
swap294: '知る',
swap295: 'アート作品',
swap296: '取引市場',
swap297: 'ブラウズ',
swap298: '即座にトークンを交換',
swap299: 'コピー成功',
swap300: 'コピーできません、このブラウザは自動コピーをサポートしていません',
swap301: '共同創業者累計報酬',
swap302: '累計シェア報酬',
swap303: '累計取引報酬',
swap304: '資産パッケージ',
swap305: '取引でコインを獲得',
swap306: '引き出せる残高',
swap307: '取引ハッシュを入力してください',
swap308: '確認',
swap309: 'ハッシュ値を入力してください',
swap310: '入金成功',
swap311: 'バイナンス',
swap312: 'プラットフォーム',
swap313: 'PHOセクション',
swap314: 'GDTセクション',
swap315: 'VGTセクション',
swap316: 'Good1680スターカード',
swap317: '個人',
swap318: 'チーム',
swap319: 'ウォレット内の',
swap320: '資産パッケージを選択',
swap321: '選択してください',
swap322: '日次収益率',
swap323: '収益割合',
swap324: '満期総数',
swap325: '購入',
swap326: 'あなたのウォレット残高',
swap327: '私の資産パッケージ',
swap328: '今日の総収益',
swap329: '今日の静的収益',
swap330: '今日の動的収益',
swap331: '累計収益',
swap332: '満期数量',
swap333: '選択',
swap334: '暗号通貨セクション',
swap335: '購入成功',
swap336: '交換数量',
swap337: '入力してください',
swap338: 'エアドロップ',
swap339: '交換成功',
swap340: '入金',
swap341: '引き出し',
swap342: '通行証を選択',
swap343: '入金数量',
swap344: '入金数量を入力してください',
swap345: 'ウォレット残高',
swap346: '利用可能残高',
swap347: '引き出し数量',
swap348: '引き出し数量を入力してください',
swap349: '通証を選択',
swap350: '請求書',
swap351: 'データなし',
swap352: 'GOOD購入',
swap353: '検索',
swap354: '番号',
swap355: '人数',
swap356: '操作',
swap357: '団体',
swap358: '団体開始',
swap359: '個人センター',
swap360: '団体開始はリーダーチケットを消費します。開始後は取り消しできません。団体成功後に豪華な報酬が得られます。',
swap361: 'グループを作成すると団長券が1枚消費され、グループ作成後は取り消せません。グループが成功すると、豪華な報酬を獲得できます。',
swap362: '現在所持している団長券：{quantity}枚',
swap363: '資産パッケージを交換',
swap364: '残高',
swap365: '団長券を消費して交換',
swap366: '残高 {quantity}枚',
swap367: 'グループ作成消費',
swap368: 'グループ番号',
swap369: 'バックパック',
swap370: '所持している団長券',
swap371: '所持している商品券',
swap372: '私が参加したグループ作成',
swap373: '役割',
swap374: 'メンバー',
swap375: 'グループ作成中',
swap376: 'グループ作成失敗',
swap377: 'グループ作成成功',
swap378: '資産',
swap379: 'ゼロ資産を非表示',
swap380: '名称',
swap381: '使用可能',
swap382: '団長',
swap383: 'あなたは{quantity}枚を所持しています',

swap384: '団長券を持っていません',
swap385: 'グループ作成成功',
swap386: 'グループ作成成功',
swap387: 'Good 購入',
swap388: 'Good Swap',
swap389: 'グループ作成で大賞を獲得',
swap390: '交換数は0にできません',
swap391: '入金中、少々お待ちください',
swap392: '不足',
swap393: '出金成功',
swap394: '私のグループ作成星級',
swap395: 'グループ作成回数',
swap396: '次のレベル',
swap397: 'グループ作成消費',
swap398: 'グループ作成なしで直接交換',
swap399: 'チェーンゲーム',
swap400: '他のセクションが順次ログイン中...',
swap401: '配布済み',
swap402: 'ネットワークを選択',
swap403: '1つ星カード：100の有効な直推、チーム500の有効なアドレス（最小で4ラインにそれぞれ100の有効アドレスがあるチーム）、1000GDT資産パッケージのプレゼント、取引税収0.1%を分配',
swap404: '2つ星カード：5つのラインにそれぞれ1つ星チームが出現すると、2000GDT資産パッケージのプレゼント、取引税収0.1%を分配',
swap405: '3つ星カード：5つのラインにそれぞれ2つ星チームが出現すると、5000GDT資産パッケージのプレゼント、取引税収0.1%を分配',
swap406: '4つ星カード：5つのチームにそれぞれ3つ星チームが出現すると、10000GDT資産パッケージのプレゼント、取引税収0.1%を分配',
swap407: '5つ星カード：5つのチームにそれぞれ4つ星チームが出現すると、50000GDT資産パッケージのプレゼント、取引税収0.1%を分配',
swap408: 'ヒント：',
swap409: '星級は毎日変動し、各星級は1回のみプレゼントされます。',
swap410: '現在有効な直推',
swap411: '現在有効なチーム',

header1: '推薦招待コード',
header2: '権利センター',
header3: 'ホーム',
header4: 'PHOゾーン',
header5: 'GDTゾーン',
header6: 'VGTゾーン',
header7: 'エンターテイメントゲーム',
header8: '問題フィードバック',
header9: '価値向上モール',
header10: 'コミュニティ連盟',
header11: '請求書',
header12: '出金',
header13: '送金',
header14: 'GDTセンター',

header15: 'GDT請求書',
header16: 'GDT個人センター',
header17: 'VGT請求書',
header18: 'VGT個人センター',
header19: 'GDT資産パッケージ',
header20: 'VGT資産パッケージ',
header21: 'VGTセンター',

footer1: 'ホーム',
footer2: '取引',
footer3: 'マイニングプール',
footer4: 'エアドロップ',
footer5: 'クラブ',

home1: '資産',
home2: '通貨',
home3: 'アカウント',
home4: 'ウォレット',
home5: '情報',
home6: '今日の価格',
home7: '取引に行く',
home8: '総供給量',
home9: 'コインを稼ぐ',
home10: '取引税',
home11: '双方向',
home12: '市場',
home13: '人気',
home14: 'について',
home15: 'GoodSwapは、複数のブロックチェーンに対応した分散型取引プラットフォームで、アライアンス経済を中心に発展を目指し、「コミュニティ経済」の概念を提唱しています。DAO構造の優位性を活かし、エコシステムの大規模なデータを活用して、安全で信頼できる、安定した、長期的かつ多様な、地域を越えた収益機会をユーザーに提供します。',
home16: '利点',
home17: '豊富な経験',
home18: 'チームのコアメンバーはシンガポールや韓国などから来ており、SushiSwapやDODOなどのプラットフォーム開発に参加した経験があります。',
home19: 'コミュニティ共建',
home20: 'GoodSwapは、「1つの中心、複数のアライアンス」の経済理念に基づき、コミュニティの力を十分に活用し、DAO型のガバナンス組織を実現します。',
home21: '安全保障',
home22: 'GoodSwapのすべての契約はオープンソースで、監査を受けています。オープンソースでないまたは監査が未実施のトークンは協力を拒否します。',
home23: 'アライアンス計画',
home24: 'GoodSwapはエコシステムアライアンスを中心に、複数のエコシステムを進出させ、ユーザーに安全で信頼性の高い、安定した、多様な収益機会を提供します。',
home25: '監査機関',
home26: 'パートナー',
home27: '入金',
home28: '出金',
home29: '請求書',
home30: '理解する',
home31: '取引はこちら',
home32: '高効率',
home33: '多様な収益',
home34: '新規上場',
home35: 'クロスチェーン',
home36: 'お知らせ一覧',
home37: 'お知らせ詳細',
home38: '通貨',
home39: '最新価格',
home40: '価格変動',
home41: '送金',
home42: '送金額',
home43: '送金額を入力してください',
home44: '受取住所',
home45: '受取住所を入力してください',
home46: '実際の送金額',
home47: '残高不足',
home48: '通証を選択してください',
home49: '署名失敗',

drop1: '一般的なエアドロップ',
drop2: 'GoodSwapは、保有者に無限の価値と権利を提供することを目指しています。アカウントに1 GDTを保持することで有効ユーザーとなり、翌日からエアドロップ権利を得ることができます。毎日受け取る必要があります。',
drop3: 'マイニング規則',
drop4: '有効ユーザーは日々のエアドロップを受け取ることができます。アカウントに1 GDTを保持することで、有効ユーザーとなり、翌日からエアドロップを受け取ることができます。',
drop5: '日常のエアドロップはランダムな通貨で、プラットフォームの発展とイベントの変更に応じて情報を確認してください。受け取らなかったエアドロップは累積されません。指定された時間内に受け取る必要があります。',
drop6: 'GDT残高',
drop7: '受け取った回数',
drop8: '今日のエアドロップ',
drop9: '通貨リスト',
drop10: '受け取ることができる',
drop11: '開始',
drop12: '受け取る',
drop13: '報酬なし',
drop14: '基準に達していない',

invite1: '私の招待コード',
invite2: 'リンクコードをコピー',
invite3: '私の紹介者',
invite4: '紹介者の招待コードを入力してください',
invite5: '最大算力エリア',
invite6: '他の算力エリア',
invite7: '検索',
invite8: '招待コードを入力してください',
invite9: '確定',
invite10: '紹介者を設定してください',
invite11: 'まだ記録されていません',
}