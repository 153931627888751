export const lang = {
    swap1: '스왑',
    swap2: '즉시 스왑 시작',
    swap3: 'from',
    swap4: '잔액: ',
    swap5: '통화 선택',
    swap6: '최대',
    swap7: '가격',
    swap8: '슬리피지 허용',
    swap9: '지갑 연결',
    swap10: '수량 입력',
    swap11: '잔액 부족',
    swap12: '이 거래의 유동성 부족',
    swap13: '토큰 선택',
    swap14: '승인',
    swap15: '포장',
    swap16: '풀기',
    swap17: '스왑',
    swap18: '거래 영수증',
    swap19: '확인을 기다리고 있습니다',
    swap20: '전송 중',
    swap21: '으로 교환',
    swap22: '지갑에서 이 거래를 확인하세요',
    swap23: '거래 제출됨',
    swap24: '블록체인 브라우저에서 보기',
    swap25: '닫기',
    swap26: '거래 거부',
    swap27: '스왑 확인',
    swap28: '출력은 예측된 값으로, 최소 {inputOutMin} {symbol}을 받게 됩니다. 가격이 변동하면 거래가 취소됩니다.',
    swap29: '입력은 예측된 값으로, 최대 {inputInMax} {symbol}을 판매하게 됩니다. 가격이 변동하면 거래가 취소됩니다.',
    swap30: '최소 획득량',
    swap31: '최대 판매량',
    swap32: 'to',
    swap33: '토큰 관리',
    swap34: '토큰 가져오기',
    swap35: '가져오기',
    swap36: '토큰 관리',
    swap37: '사용자 정의 토큰',
    swap38: '모두 지우기',
    swap39: '이름 검색 또는 주소 붙여넣기',
    swap40: '설정',
    swap41: '기본 거래 속도(GWEI)',
    swap42: '표준',
    swap43: '빠름',
    swap44: '즉시',
    swap46: '유효한 슬리피지 비율 입력',
    swap47: '거래가 실패할 수 있습니다',
    swap48: '거래가 우선 처리될 수 있습니다',
    swap49: '거래 마감 시간(분)',
    swap50: '예상',
    swap51: '멀티홉 비활성화',
    swap52: '최근 거래',
    swap53: '최근 거래 없음',
    swap54: '모두 지우기',
    swap55: '연결되지 않음',
    swap56: '이사회 참여',
    swap57: '기부',
    swap58: '권리 기부',
    swap59: '이사회 멤버 목록',
    swap60: '초대',
    swap61: '복사',
    swap62: '내 DAPP 초대자:',
    swap63: '초대 기록',
    swap64: '오류 발생, 나중에 다시 시도하세요',
    swap65: '기능 미개방',
    swap66: '수령',
    swap67: '출금 성공',
    swap68: '기부 중, 확인 대기 중',
    swap69: '기부 완료',
    swap70: '화이트리스트가 아닌 사용자',
    swap71: '기부 무효',
    swap72: '현재 잔액 부족',
    swap73: '승인 중, 잠시 기다려 주세요',
    swap74: '승인 실패',
    swap75: '기부 중, 잠시 기다려 주세요',
    swap76: '기부 실패',
    swap77: '교환',
    swap78: '유동성',
    swap79: '회원',
    swap80: '초대',
    swap81: '권리 풀',
    swap82: '크로스체인 브리지',
    swap83: '풀',
    swap84: '농장',
    swap85: '홈',
    swap86: '마켓',
    swap87: '교환',
    swap88: '거래',
    swap89: '벌기',
    swap90: '확인',
    swap91: '가격 영향',
    swap92: '최소 획득량',
    swap93: '유동성 공급자 수수료',
    swap94: '유동성 추가',
    swap95: '유동성 추가하여 LP 토큰 받기',
    swap96: '유동성 풀의 비율',
    swap97: '환율과 유동성 풀 비율',
    swap98: '당신의 유동성',
    swap99: '유동성 제거하여 토큰 회수',
    swap100: '유동성 없음',
    swap101: '가입한 유동성 풀 보이지 않나요?',
    swap102: '다른 LP 토큰 보기',
    swap103: '풀에 추가됨',
    swap104: '제거',
    swap105: '공급',
    swap106: '풀 가져오기',
    swap107: '기존 유동성 풀 가져오기',
    swap108: '토큰을 선택하여 유동성을 찾으세요.',
    swap109: '이 유동성 풀에 유동성을 제공하지 않았습니다.',
    swap110: '지갑의 LP 토큰',
    swap111: '수령',
    swap112: '와',
    swap113: '금액',
    swap114: '당신은 받게 됩니다',
    swap115: '출력은 예측된 값입니다. 가격 변동이 {slippage}%를 초과하면 거래가 취소됩니다.',
    swap116: '소각됨',
    swap117: '입금됨',
    swap118: '공급 확인',
    swap119: '환율',
    swap120: '당신은 첫 번째 유동성 공급자입니다.',
    swap121: '토큰을 추가한 비율이 해당 유동성 풀의 초기 가격을 결정합니다.',
    swap122: '환율에 만족하면 "공급"을 클릭하여 확인하십시오.',
    swap123: '제거 중',
    swap124: '공급 중',
    swap125: '공급 확인',
    swap126: '백서',
    swap127: '감사',
    swap128: '공지',
    swap129: '알림',
    swap130: 'PHO 1:1 교환',
    swap131: '더보기',
    swap132: '로딩 중',
    swap133: '정보',
    swap134: '기부 자리가 마감되었습니다',
    swap135: '내 PHO',
    swap136: '지갑의 PHO',
    swap137: '수확 가능한 PHO',
    swap138: 'PHO 가격',
    swap139: 'PHO 총량',
    swap140: 'PHO 시가 총액',
    swap141: 'PHO 정보',
    swap142: '벌어들인',
    swap143: '스테이킹',
    swap144: '개인 TVL',
    swap145: '커뮤니티 TVL',
    swap146: '수확',
    swap147: '누적 보상',
    swap148: '오늘 보상',
    swap149: '시장 메이커 참여 주소',
    swap150: '내 초대 링크',
    swap151: '최대 계산 구역',
    swap152: '기타 계산 구역',
    swap153: '내 친구 초대',
    swap154: '취소',
    swap155: '초대자 수정',
    swap156: '초대자는 비워둘 수 없습니다',
    swap157: '수정 성공',
    swap158: 'LP 토큰 스테이킹',
    swap159: 'LP 토큰 상환',
    swap160: '상환',
    swap161: '획득',
    swap162: '현재 수익 없음',
    swap163: '라우팅',
    swap164: '감사 기관',
    swap165: '파트너',
    swap166: '발행',
    swap167: '이벤트',
    swap168: '단일 통화 풀',
    swap169: 'LP 풀',
    swap170: '국회',
    swap171: '카드',
    swap172: '블라인드 박스',
    swap173: '게임',
    swap174: '권리 풀 할당 총액',
    swap175: '수령 대기 중',
    swap176: '내가 받은 금액',
    swap177: '출금',
    swap178: '슈퍼 노드',
    swap179: '권리 카드 수령된 비율',
    swap180: '국고',
    swap181: '국고 누적 금액',
    swap182: '국고 할당 금액',
    swap183: '국고 재구매 금액',
    swap184: '전문가 모드',
    swap185: 'GoodSwap은 OKExChain을 기반으로 개발된 최초의 주식 세금 제도 Defi 플랫폼으로, 점차적으로 Swap, NFT, Gamefi, Lend, 기관총 풀 등 섹션을 출시하고 있습니다. 출시 이후 선도적인 기술과 공정하고 공개적인 시장 원칙을 통해 더 넓은 자산 가치 증대 방안과 전례 없는 경험을 제공하여 많은 사용자들의 사랑을 받고 있습니다.',
swap186: '하나 클릭으로 출금',
swap187: '가격 영향이 너무 큼',
swap188: 'FAQ',
swap189: 'NFT',
swap190: '코인 벌기',
swap191: '진행 중',
swap192: '끝남',
swap193: '설명',
swap194: '주식 권리 생태 카드, 전 세계 한정 1680장',
swap195: '주식 권리 생태 카드를 활성화하는 요구 사항 및 수익은 다음과 같습니다.',
swap196: '1성 카드: 개인 주소에 1000USDT의 유동성, 팀에 1000USDT의 유동성, 수익은 거래 세금 0.1%로 나뉩니다.',
swap197: '2성 카드: 개인에 2000USDT 유동성, 팀에 1만 USDT 유동성, 수익은 거래 세금 0.1%로 나뉩니다.',
swap198: '3성 카드: 개인 3000USDT 유동성, 팀에 10만 USDT 유동성, 스튜디오 설립, 수익은 거래 세금 0.1%로 나뉩니다.',
swap199: '4성 카드: 개인 4000USDT 유동성, 팀에 100만 USDT 유동성, 스튜디오 설립, 수익은 거래 세금 0.1%로 나뉩니다.',
swap200: '5성 카드: 개인 5000USDT 유동성, 팀에 1000만 USDT 유동성, 스튜디오 설립, 수익은 거래 세금 0.1%로 나뉩니다.',
swap201: '참고: 모든 권리 생태 카드의 수익은 체인 상에서 자동으로 분배되며, 권리 카드는 거래소의 모든 생태 배당을 받을 수 있으며, 권리 카드는 전송 가능하고 거래 가능하며 요구 사항을 충족하면 자동으로 업그레이드되고, 충족하지 않으면 자동으로 하향되며 자동으로 탈퇴됩니다.',
swap202: '권리 카드',
swap203: '개발 로드맵:',
swap204: '2022년 7월  2.0 버전 출시',
swap205: '2022년 9월  코인 보유 다중 채굴 개시',
swap206: '2022년 12월 NFT 거래 플랫폼 출시',
swap207: '2023년 6월  Goodbaby 게임 출시',
swap208: '2023년 9월  Goodlend 출시',
swap209: 'GoodSwap 소개:',
swap210: '슬리피지:',
swap211: '배분:',
swap212: '구매',
swap213: '판매',
swap214: '0.5% LP 배당',
swap215: '0.5% 생태권리 카드 배당',
swap216: '0.25% 리플로우 풀 소각 LP',
swap217: '0.25% 생태 구축',
swap218: '보상 수령 없음',
swap219: '개인 TVL',
swap220: '팀 TVL',
swap221: 'LP 수량',
swap222: '지갑 내 PHO',
swap223: 'GDT',
swap224: 'GDT 에어드롭 누적 보상',
swap225: '별 등급 사용자 누적 보상',
swap226: '수수료 면제 카운트다운',
swap227: '수령 가능한 보상',
swap228: 'LP 채굴 보상',
swap229: 'PHO 스테이킹',
swap230: 'PHO 리디엠',
swap231: '목표 합의',
swap232: '합의 도달',
swap233: '합의 완료',
swap234: '합의',
swap235: '해제됨',
swap236: '수령 가능',
swap237: '커뮤니티 거버넌스',
swap238: '연합 합의',
swap239: '커뮤니티 연합',
swap240: '스테이킹 토큰',
swap241: '스테이킹 중, 잠시만 기다려주세요',
swap242: '트레이딩 마이닝',
swap243: '어제 추가',
swap244: '커뮤니티 화이트리스트 누적 보상',
swap245: '주식 스타 카드 누적 보상',
swap246: 'LP 채굴 누적 보상',
swap247: '현재 수령 가능',
swap248: '현재 수령 가능한 금액',
swap249: '트레이딩 마이닝',
swap250: '내 공유 보상 누적',
swap251: '내 거래 보상 누적',
swap252: '거래 해시를 입력하세요',
swap253: '검증',
swap254: '해시를 입력하세요',
swap255: '귀하의 거래 주소',
swap256: '받을 금액',
swap257: 'GDT 구매 및 유동성 생성 거래에서만 보상을 받을 수 있습니다.',
swap258: 'GDT-PHO 토큰 스테이킹',
swap259: 'GDT-PHO 토큰 리디엠',
swap260: '전체 네트워크 수수료 누적',
swap261: '대기 중인 배당',
swap262: 'GDT 공동 주주',
swap263: '소셜',
swap264: '상점',
swap265: '선정된 좋은 상품',
swap266: '선정된 작은 가게',
swap267: '곧 출시',
swap268: 'VGT 공동 창립 주주',
swap269: 'VGT-PHO 토큰 스테이킹',
swap270: 'VGT-PHO 토큰 리디엠',
swap271: '추천 링크',
swap272: '먼저 초대자를 추가하세요',
"swap273": "상세",
"swap274": "메타버스",
"swap275": "전역",
"swap276": "교환과 유동성",
"swap277": "OKExChain을 기반으로 개발된 전 세계 최초의 주식세 기반 Defi 플랫폼으로, 점진적으로 출시됩니다.",
"swap278": "총알풀 등 블록을 단계적으로 출시합니다. 출시 이후, 선도적인 기술과 공정한 시장 철학을 통해 더 넓은 자산 가치 상승 방안을 제공하고, 전례 없는 경험감을 제공하여 많은 사용자들에게 사랑받고 있습니다.",
"swap279": "발전 로드맵",
"swap280": "2022년 7월 2.0 버전 출시",
"swap281": "2022년 9월 보유한 토큰으로 다중 채굴 시작",
"swap282": "2022년 12월 NFT 거래 플랫폼 출시",
"swap283": "2023년 6월 Goodbaby 게임 출시",
"swap284": "2023년 9월 Goodlend 출시",
"swap285": "자세히 알아보기",
"swap286": "보유 중",
"swap287": "에서",
"swap288": "암호화폐 거래",
"swap289": "유동성 및 토큰 스테이킹",
"swap290": "배당 보상 얻기",
"swap291": "발사대",
"swap292": "인증",
"swap293": "고급 암호화 자산",
"swap294": "알아보기",
"swap295": "예술품",
"swap296": "거래 시장",
"swap297": "브라우징",
"swap298": "즉시 토큰 교환",
"swap299": "복사 성공",
"swap300": "복사 불가, 해당 브라우저는 자동 복사를 지원하지 않습니다.",
"swap301": "공동 창작 누적 보상",
"swap302": "누적 공유 보상",
"swap303": "누적 거래 보상",
"swap304": "자산 패키지",
"swap305": "거래로 코인 얻기",
"swap306": "출금 가능한 잔액",
"swap307": "거래 해시를 입력하세요",
"swap308": "확인",
"swap309": "해시 값을 입력하세요",
"swap310": "입금 성공",
"swap311": "바이낸스",
"swap312": "플랫폼",
"swap313": "PHO专区",
"swap314": "GDT专区",
"swap315": "VGT专区",
"swap316": "Good1680별 등급 카드",
"swap317": "개인",
"swap318": "팀",
"swap319": "지갑의",
"swap320": "자산 패키지 선택",
"swap321": "선택하세요",
"swap322": "일일 수익률",
"swap323": "수익 비율",
"swap324": "만기 총 수",
"swap325": "구매",
"swap326": "지갑 잔액",
"swap327": "내 자산 패키지",
"swap328": "오늘의 총 수익",
"swap329": "오늘의 정적 수익",
"swap330": "오늘의 동적 수익",
"swap331": "누적 수익",
"swap332": "만기 수",
"swap333": "선택",
"swap334": "암호화폐专区",
"swap335": "구매 성공",
"swap336": "교환 수량",
"swap337": "입력하세요",
"swap338": "에어드랍",
"swap339": "교환 성공",
"swap340": "충전",
"swap341": "출금",
"swap342": "통행 선택",
"swap343": "충전 수량",
"swap344": "충전 수량을 입력하세요",
"swap345": "지갑 잔액",
"swap346": "사용 가능한 잔액",
"swap347": "출금 수량",
"swap348": "출금 수량을 입력하세요",
"swap349": "통화 선택",
"swap350": "청구서",
"swap351": "데이터 없음",
"swap352": "GOOD 구매",
"swap353": "검색",
"swap354": "번호",
"swap355": "인원",
"swap356": "작업",
"swap357": "그룹 구매",
"swap358": "그룹 시작",
"swap359": "개인 센터",
"swap360": "그룹 시작은 그룹 리더 쿠폰을 소모하며, 그룹을 시작한 후에는 취소할 수 없습니다. 그룹 구매 성공 후 풍성한 보상을 받습니다.",
swap361: '발단은 한 장의 단장 쿠폰을 소모하며, 발단 후에는 철회할 수 없습니다. 단체가 성공하면 풍성한 보상을 받게 됩니다.',
swap362: '현재 보유한 단장 쿠폰: {quantity}장',
swap363: '자산 패키지 교환',
swap364: '잔액',
swap365: '단장 쿠폰 소모 교환',
swap366: '잔액 {quantity}장',
swap367: '단체 소비',
swap368: '단체 번호',
swap369: '백팩',
swap370: '보유한 단장 쿠폰',
swap371: '보유한 상품 쿠폰',
swap372: '내가 참여한 단체',
swap373: '역할',
swap374: '단원',
swap375: '단체 진행 중',
swap376: '단체 실패',
swap377: '단체 성공',
swap378: '자산',
swap379: '제로 자산 숨기기',
swap380: '이름',
swap381: '사용 가능',
swap382: '단장',
swap383: '당신은 {quantity}장을 보유하고 있습니다',

swap384: '단장 쿠폰이 없습니다',
swap385: '발단 성공',
swap386: '단체 성공',
swap387: 'Good 구입',
swap388: 'Good Swap',
swap389: '단체로 큰 상 받기',
swap390: '교환 수량은 0일 수 없습니다',
swap391: '입금 중, 잠시 후에 확인하십시오',
swap392: '부족',
swap393: '출금 성공',
swap394: '내 단체 별점',
swap395: '단체 횟수',
swap396: '다음 레벨',
swap397: '단체 소비',
swap398: '단체 발단 없이 바로 교환',
swap399: '체인 게임',
swap400: '더 많은 섹션이 차례로 로그인 중...',
swap401: '배포 완료',
swap402: '네트워크 선택',
swap403: '일성 카드: 100개의 유효한 직추, 팀 500개의 유효한 주소(각각 100개의 유효한 주소가 있는 4개의 라인이 있어야 함), 1000GDT 자산 패키지 선물 및 거래세 0.1% 배분',
swap404: '이성 카드: 다섯 개의 라인에서 각각 일성 팀이 나타나면 2000GDT 자산 패키지 선물 및 거래세 0.1% 배분',
swap405: '삼성 카드: 다섯 개의 라인에서 각각 이성 팀이 나타나면 5000GDT 자산 패키지 선물 및 거래세 0.1% 배분',
swap406: '사성 카드: 다섯 개의 팀에서 각각 삼성 팀이 나타나면 10000GDT 자산 패키지 선물 및 거래세 0.1% 배분',
swap407: '오성 카드: 다섯 개의 팀에서 각각 사성 팀이 나타나면 50000GDT 자산 패키지 선물 및 거래세 0.1% 배분',
swap408: '알림:',
swap409: '별점은 매일 변동되며, 각 별점은 한 번만 선물됩니다;',
swap410: '현재 유효한 직추',
swap411: '현재 유효한 팀',

header1: '추천 초대 코드',
header2: '권리 센터',
header3: '홈',
header4: 'PHO 구역',
header5: 'GDT 구역',
header6: 'VGT 구역',
header7: '엔터테인먼트 게임',
header8: '문제 피드백',
header9: '가치 증대 몰',
header10: '커뮤니티 연합',
header11: '청구서',
header12: '출금',
header13: '송금',
header14: 'GDT 센터',

"header15": "GDT 청구서",
"header16": "GDT 개인 센터",
"header17": "VGT 청구서",
"header18": "VGT 개인 센터",
"header19": "GDT 자산 패키지",
"header20": "VGT 자산 패키지",
"header21": "VGT 센터",

"footer1": "홈",
"footer2": "거래",
"footer3": "마이닝 풀",
"footer4": "에어드랍",
"footer5": "클럽",

"home1": "자산",
"home2": "코인 종류",
"home3": "계정",
"home4": "지갑",
"home5": "정보",
"home6": "오늘의 가격",
"home7": "거래로 가기",
"home8": "공급 총량",
"home9": "코인 벌기",
"home10": "거래 세금",
"home11": "양방향",
"home12": "시장",
"home13": "인기",
"home14": "소개",
"home15": "GoodSwap은 다중 체인 구축을 목표로 하는 탈중앙화 거래 플랫폼으로, 연합 경제를 발전의 핵심으로 삼고, '커뮤니티 경제' 개념을 지지하며, DAO 조직 구조의 장점을 활용하여 생태계 빅데이터를 구축하여 사용자에게 안전하고 신뢰할 수 있으며 안정적이고 장기적이며 다양한 수익 기회를 제공합니다!",
"home16": "장점",
"home17": "풍부한 경험",
"home18": "핵심 팀은 싱가포르, 한국 등 출신이며, SushiSwap, DODO 등 플랫폼 구축에 참여한 경험이 있습니다. 탈중앙화 플랫폼 경험이 풍부합니다!",
"home19": "커뮤니티 공동 구축",
"home20": "GoodSwap은 '하나의 중심, 여러 연합' 경제 개념을 지향하며, 커뮤니티 거버넌스의 힘을 충분히 활용하여 DAO형 거버넌스 조직을 실현하고자 합니다.",
"home21": "보안 보장",
"home22": "GoodSwap의 모든 계약은 오픈소스로 제공되며 철저한 감사가 이루어집니다. 오픈되지 않은 또는 검토되지 않은 토큰과는 협력하지 않습니다!",
"home23": "연합 계획",
"home24": "GoodSwap은 생태 연합을 중심으로 다양한 생태계를 추진하여 사용자에게 안전하고 신뢰할 수 있으며 안정적이고 다양한 수익 기회를 제공합니다!",
"home25": "감사 기관",
"home26": "파트너",
"home27": "입금",
"home28": "출금",
"home29": "청구서",
"home30": "알기",
"home31": "거래 하러 가기",
"home32": "고효율",
"home33": "다양한 수익",
"home34": "새롭게 출시",
"home35": "브릿지 체인",
"home36": "공지 목록",
"home37": "공지 사항 상세",
"home38": "코인 종류",
"home39": "최신 가격",
"home40": "등락폭",
"home41": "송금",
"home42": "송금 수량",
"home43": "송금 수량을 입력하세요",
"home44": "수취 주소",
"home45": "수취 주소를 입력하세요",
"home46": "실제 송금",
"home47": "잔액 부족",
"home48": "통화 종류를 선택하세요",
"home49": "서명 실패",

"drop1": "일반 에어드랍",
"drop2": "GoodSwap은 보유자에게 무한한 가치와 권리를 창출하기 위해 노력합니다. 1 GDT를 보유하고 있으면 유효 사용자가 되어 다음 날부터 에어드랍 권리를 받을 수 있으며, 매일 수령해야 하며 계정을 활성화 상태로 유지해야 합니다.",
"drop3": "채굴 규칙",
"drop4": "유효 사용자는 일일 에어드랍을 받을 수 있습니다. 유효 사용자는 계정에 1 GDT를 보유하고 있어야 하며, 그 다음 날부터 에어드랍을 받을 수 있습니다. 수령 시 계정을 활성화 상태로 유지해야 합니다.",
"drop5": "일일 에어드랍은 무작위 코인 종류로 제공되며, 플랫폼 발전과 활동에 따라 변동이 있을 수 있습니다. 관련 정보를 주의 깊게 확인하십시오. 당일 수령하지 않은 에어드랍은 누적되지 않습니다. 규정된 시간 내에 수령해야 합니다.",
"drop6": "GDT 잔액",
"drop7": "수령 횟수",
"drop8": "오늘의 에어드랍",
"drop9": "코인 종류 목록",
"drop10": "수령 가능",
"drop11": "입장",
"drop12": "수령",
"drop13": "보상 없음",
"drop14": "평가 기준에 미달",

"invite1": "내 초대 코드",
"invite2": "링크 코드 복사",
"invite3": "내 초대인",
"invite4": "추천인 초대 코드를 입력하세요",
"invite5": "최대 해시 구역",
"invite6": "기타 해시 구역",
"invite7": "검색",
"invite8": "초대 코드 입력",
"invite9": "확인",
"invite10": "추천인을 연결하세요",
"invite11": "기록 없음"
}